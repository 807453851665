import React from 'react';

import SEO from '../layout/seo';
import { Page, Section } from '../layout/page';
import Headline from '../layout/headline';

const IndexPage = () => (
  <Page color="black">
    <SEO title="Home" />
    <Section color="black">
      <Headline
        background="black"
        lead="Main"
        emphasis="Stuff"
        subtitle="Stuff goes here."
      />
    </Section>
    <Section color="blue">
      <Headline
        background="blue"
        lead="More"
        emphasis="Things"
        subtitle="Things go here."
      />
    </Section>
  </Page>
);

export default IndexPage;
