import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './headline.module.less';

type Props = {
  lead?: ReactNode;
  emphasis?: ReactNode;
  subtitle?: ReactNode;
  background?: string;
  className?: string;
};

const Headline = ({
  lead,
  emphasis,
  subtitle,
  background = 'white',
  className = '',
}: Props) => {
  return (
    <div className={classNames(styles.headline, styles[background], className)}>
      {(lead || emphasis) && (
        <h2 className={styles.title}>
          {lead}
          {emphasis && (
            <span className={classNames(styles.emphasis)}>{emphasis}</span>
          )}
        </h2>
      )}
      {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
    </div>
  );
};

export default Headline;
